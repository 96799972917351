import React from "react"
import PropTypes from "prop-types"
import Observer from "@researchgate/react-intersection-observer"

class ViewportBlock extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { show: false }
    this.handleViewport = this.handleViewport.bind(this)
  }
  
  handleViewport(event, unobserve) {
    if (!event.isIntersecting) {
        return
    }
    if (event.defaultPrevented) {
        return
    }
    unobserve()
    this.setState({ show: true })
    setTimeout(()=>{
      event.target.className = event.target.className + " active";
    }, 200)
  }

  render() {
    const {
      children,
      component,
      forwardedRef: ref,
      src,
      dataSrc,
      ...rest
    } = this.props

    let props = Object.assign({ ref }, rest)
    
    if(component === "video" && this.state.show) {
      props.src = props.datasrc
    }

    return (
      <Observer onChange={this.handleViewport} rootMargin="-10px">
        {React.createElement(
            component,
            props,
            this.state.show ? children : null,
        )}
      </Observer>
    )
  }
}

ViewportBlock.defaultProps = {
  component: 'div',
}

ViewportBlock.propTypes = {
  children: PropTypes.node,
  component: PropTypes.any,
  forwardedRef: PropTypes.any,
}

export default React.forwardRef((props, ref) => <ViewportBlock {...props} forwardedRef={ref} />)


