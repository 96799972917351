import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
// styles
import * as styles from "../../css/menuzord.module.css"
import BgsLogo from "../icons/BgsLogo"

// markup

const Menuzord = ({ pageName, light = false }) => {
  const [navbar, setNavbar] = useState(false)
  const [lightnavbar, setLightnavbar] = useState(false)
  const [transNavbar, setTransNavbar] = useState(false)
  const [animateNavbar, setAnimateNavbar] = useState(false)
  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)

  useEffect(() => {
    window.addEventListener('scroll', changeBackground)
    if (light) {
      setLightnavbar(true);
    } else {
      setLightnavbar(false);
    }
  }, [])

  const changeBackground = () => {
    if (window.scrollY >= 200) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
    if (window.scrollY >= 350) {
      setTransNavbar(true)
    } else {
      setTransNavbar(false)
    }
    if (window.scrollY >= 600) {
      setAnimateNavbar(true)
    } else {
      setAnimateNavbar(false)
    }
  }
  
  return (
    <div className={animateNavbar ? styles.menuzordContainerAnimate : transNavbar ? styles.menuzordContainerTrans : navbar ? styles.menuzordContainerFixed : lightnavbar ? styles.menuzordContainerLight : styles.menuzordContainer}>
      <div className="container">  
        <button className={styles.showHide} onClick={() => handleClick()}>
          <em></em>
          <em></em>
          <em></em>
        </button>
        <a href="/" className={styles.menuzordBrand} >
          <div className={styles.menuzordSvg}><BgsLogo/></div>
          <h1>БГС</h1>
        </a>
        <ul className={ click ? styles.showhidemenu : navbar || lightnavbar ? styles.menuzordMenu2 : styles.menuzordMenu}>
          <li className={ pageName === "main" ? styles.active : "" }><Link to="/">Главная</Link></li>
          <li className={ pageName === "news" ? styles.active : "" }><Link to="/news">Новости</Link></li>
          <li className={ pageName === "licenses" ? styles.active : "" }><Link to="/licenses">Лицензии</Link></li>
          <li className={ pageName === "about" ? styles.active : "" }><Link to="/about">О компании</Link></li>
          <li className={ pageName === "contact" ? styles.active : "" }><Link to="/contact">Контакты</Link></li>
        </ul>
      </div>
    </div>
  )
}

export default Menuzord
