import * as React from "react"
import ViewportBlock from "../viewportBlock"

const ContactusItem = ({ icon, title, children, styles }) => {
  return (
    <ViewportBlock className={styles.contactItem + ' reveal'}>
      <div className={styles.contactIconContaner}>
        <div className={styles.contactIcon}>
          {icon}
        </div>
      </div>
      <div className={styles.contactDescription}>
        <h5>{title}</h5>
        <span dangerouslySetInnerHTML={{ __html: children}} />
      </div>
    </ViewportBlock>
  )
}

export default ContactusItem
