// extracted by mini-css-extract-plugin
export var alertClose = "contactus-module--alertClose--HPdEA";
export var alertError = "contactus-module--alertError--NzkQ4";
export var alertSuccess = "contactus-module--alertSuccess--OQGMy";
export var btnSubmit = "contactus-module--btnSubmit--Pi4Wg";
export var contactDescription = "contactus-module--contactDescription--4OyB3";
export var contactFields = "contactus-module--contactFields--Y+ts3";
export var contactForm = "contactus-module--contactForm--EoF17";
export var contactIcon = "contactus-module--contactIcon--u6tYA";
export var contactIconContaner = "contactus-module--contactIconContaner--3g59f";
export var contactItem = "contactus-module--contactItem--wmuSf";
export var contacts = "contactus-module--contacts--m2Y9+";
export var contactusMinititle = "contactus-module--contactusMinititle--2kSTh";
export var contactusRow = "contactus-module--contactusRow--sLQDC";
export var contactusSection = "contactus-module--contactusSection--7VOsz";
export var contactusSectionHead = "contactus-module--contactusSectionHead--FiCSx";
export var contactusSectionTitle = "contactus-module--contactusSectionTitle--dllHI";
export var disabled = "contactus-module--disabled--Q6uu3";
export var itemForm = "contactus-module--itemForm--Kp9xz";
export var loading = "contactus-module--loading--nBn74";