import * as React from "react"

// styles

// markup
const IonAt = () => {
  return (
    <svg viewBox="0 0 35 35">
        <path d="M 12.794898,34.320539 C 9.2896239,33.378405 6.9500849,32.054998 4.8827939,29.844898 -1.0934411,23.455821 -1.6463591,13.81119 3.5697289,6.9409448 6.7553349,2.7451078 11.225002,0.41992975 16.768153,0.07495975 c 7.942799,-0.494315 14.179138,2.56568905 17.023339,8.35289005 1.338958,2.7244242 1.626205,8.6805232 0.554032,11.4879622 -1.501556,3.931763 -4.939882,6.513013 -8.700703,6.531861 -1.587566,0.0079 -2.084081,-0.215044 -3.263763,-1.465874 l -1.39135,-1.475262 -0.794624,0.878048 c -2.974423,3.286707 -8.275009,2.613017 -10.6015571,-1.34742 -2.170127,-3.694174 -0.400819,-10.26069 3.4999491,-12.989528 2.617008,-1.8307622 6.098035,-2.0483252 8.334667,-0.5209102 1.198297,0.8183252 1.602396,0.9358962 1.602396,0.466205 0,-0.470733 0.54772,-0.628079 2.186362,-0.628079 2.058623,0 2.171345,0.05617 1.929353,0.9614362 -1.082342,4.048842 -2.083537,10.118039 -1.832732,11.109932 0.415412,1.642896 2.114578,1.589612 3.708121,-0.116278 2.490122,-2.665679 2.606173,-8.132296 0.255879,-12.0529872 -2.323885,-3.876634 -6.447044,-5.722583 -11.981682,-5.364232 -5.37819,0.348218 -9.0866101,2.828426 -11.3063101,7.5617032 -0.96307,2.053647 -1.1189,2.954238 -1.115439,6.446533 0.0032,3.149679 0.183827,4.394559 0.806333,5.554967 1.584086,2.952879 3.296274,4.670055 5.9678771,5.985284 5.104432,2.512902 11.609775,2.081889 16.396499,-1.086358 1.230226,-0.814269 2.418928,-1.480487 2.641558,-1.480487 0.222631,0 0.836488,0.60628 1.364136,1.347289 l 0.959347,1.347282 -1.250733,1.027003 c -4.604324,3.78072 -12.768468,5.379869 -18.96421,3.714604 z m 6.170395,-12.635583 c 3.518286,-2.767482 2.955728,-9.328966 -0.799833,-9.328966 -2.511215,0 -4.599188,3.09527 -4.604257,6.825477 -0.0019,1.454287 0.221646,2.09305 0.934963,2.670655 1.263424,1.02306 3.040503,0.956591 4.469127,-0.167171 z" />
    </svg>
  )
}

export default IonAt
