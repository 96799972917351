import * as React from "react"
// styles
import * as styles from "../../css/footer.module.css"

// markup
const FooterSection = () => {
  return (
    <footer>
      <div className="container">
        <div className={styles.row}>
          <div className={styles.cellLeft}>
            <p><strong>ТОО БатысГеофизСервис</strong> 2022</p>
          </div>
          <div className={styles.cellRight + ' openbit'}>
            <p>Разработано с <span>❤</span> web-студией <a href="https://openbit.kz/">Openbit</a></p>   
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterSection
