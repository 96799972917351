import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import IonAt from "../icons/IonAt"
import IonClock from "../icons/IonClock"
import IonIosLocation from "../icons/IonIosLocation"
import IonIosTelephone from "../icons/IonIosTelephone"
import ContactusItem from "./ContactusItem"
import ViewportBlock from "../viewportBlock"
import ContactForm from "../ContactForm"

// styles
import * as styles from "../../css/contactus.module.css"
import SimpleMap from "./GoogleMap"

const ContactusSection = ({ data }) => {

  const contactItems = useStaticQuery(
    graphql`
      query{
        directus {
          contacts {
            id
            title
            icon
            body
          }
        }
      }
    `
  )
  
  const nodes = contactItems.directus.contacts

  const contactItemStyles = {
    contactItem : styles.contactItem,
    contactIconContaner : styles.contactIconContaner,
    contactIcon : styles.contactIcon,
    contactDescription : styles.contactDescription
  }
  const iconArray = {
    IonIosTelephone: <IonIosTelephone/> ,
    IonAt: <IonAt/>,
    IonIosLocation: <IonIosLocation/>,
    IonClock: <IonClock/>,
  };
  
  return (
    <section className={styles.contactusSection}>
      <div className="container">
        <ViewportBlock className={styles.contactusSectionHead + ' reveal'}>
          <h1 className={styles.contactusSectionTitle}>Свяжитесь с нами</h1>  
        </ViewportBlock>
        <div className={styles.contactusRow}>
          <div className={styles.contacts}>
            <ViewportBlock className={styles.contactusMinititle + ' reveal'}>ТОО “БатысГеофизСервис”</ViewportBlock>
            {nodes.map(({ id, title, icon, body }) => {
              return (
                <ContactusItem key={id} icon={iconArray[icon]} title={title} styles={contactItemStyles}>
                  {body}
                </ContactusItem>
              )
            })}
          </div>
          <ContactForm/>
        </div>
      </div>
      <ViewportBlock>
        <SimpleMap/>
      </ViewportBlock>
    </section>
  )
}

export default ContactusSection