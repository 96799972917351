import React, { useState } from "react"
import { GoogleMap, LoadScript, InfoWindow, Marker } from "@react-google-maps/api"
import { graphql, useStaticQuery } from "gatsby";



function SimpleMap() {

  const googlemap = useStaticQuery(
    graphql`
      query{
        directus {
          google_map {
            api_key
            id
            info_window
            lat
            lng
          }
        }
      }
    `
  )
  
  const { api_key, info_window, lat, lng } = googlemap.directus.google_map
  
  const containerStyle = {
    width: '100%',
    height: '400px'
  }
  
  const center = {
    lat: parseFloat (lat),
    lng: parseFloat (lng)
  }
    
  const [activeMarker, setActiveMarker] = useState(null)
  
  return (
    <LoadScript
      googleMapsApiKey={api_key}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        onClick={() => setActiveMarker(null)}
        center={center}
        zoom={10}
      >
        <Marker
          position={center}
          onClick={() => setActiveMarker(true)}
        >
          {activeMarker ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}  >
              <span dangerouslySetInnerHTML={{ __html: info_window}}/>
            </InfoWindow>
          ) : null}
        </Marker>
      </GoogleMap>
    </LoadScript>
  )
}

export default SimpleMap;