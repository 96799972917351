import React, { useState, useRef } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import ViewportBlock from "./viewportBlock"
import MaskedInput from "react-text-mask"
import emailMask from "text-mask-addons/dist/emailMask"
// styles
import * as styles from "../css/contactus.module.css"

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  let validation = false;
  const [errorMsg, setErrorMsg] = useState("");

  const changeName = (event)=> {
    setName(event.target.value)
  }
  const changeEmail = (event) => {
    setEmail(event.target.value)
  }

  const changePhone = (event)=> {
    setPhone(event.target.value)
  }
  const changeMessage = (event)=> {
    setMessage(event.target.value)
  }
  const setDefaults = () => {
    setName("")
    setPhone("")
    setMessage("")
    setEmail("")
    validation = false
  }
  const validate = () => {
    var formValidation = name && phone ? true : false
    validation = formValidation
  }

  const form = useRef()
  const [ formFlash, setformFlash ]= useState(false)

  const resetForm = () => {
    form.current.reset()
    setformFlash(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault(); 
    validate()
    if(!loading && validation){
      setLoading(true);
      setLoaded(false);
      fetch('https://8fr0upqw.directus.app/items/customers',
      {
        method: "POST",
        headers: {
        'Content-Type': 'application/json'},
        body: JSON.stringify({name: name, phone: phone, email: email, message: message})
      })
      .then((response) => {
        if(response.ok){
          setDefaults();
          setLoading(false);
          setLoaded(true);
          setError(false);
        } else{
          setDefaults();
          setLoading(false);
          setLoaded(true);
          setError(true);
          setErrorMsg(
            <>
            <p>Что-то пошло не так!</p>
            <p>Попробуйте перезагрузить страницу и попробовать еще раз, или напишите нам на <a href="mailto:info@openbit.kz">info@openbit.kz</a></p>
            </>
          )
        }
      })
    } else{
      setError(true);
      setErrorMsg(
        <p>Пожалуйста заполните хотя бы имя и телефон!</p>
      );
    }
    setformFlash(true);
  }

  if(!loading){ 
    return (
      <ViewportBlock className={styles.contactFields}>
        <form ref={form} className={styles.contactForm}>
          {!loaded && !error ? null : "" }
          {loaded && !error && formFlash ?
            <div className={styles.alertSuccess}>
              <button type="button" className={styles.alertClose} onClick={resetForm}>
                <span>×</span>
              </button>
              <p>Ваш запрос был успешно принят мы с вами свяжемся</p>
            </div> : "" }
          { error && formFlash ? 
            <div className={styles.alertError}>
              <button type="button" className={styles.alertClose} onClick={resetForm}>
                <span>×</span>
              </button>
              {errorMsg}
            </div> : "" }
          <ViewportBlock className={styles.itemForm + ' reveal'}>
            <label htmlFor="name">Имя:</label>
            <input required type="text" id="name" name="name" value={name} onChange={changeName} />
          </ViewportBlock>
          <ViewportBlock className={styles.itemForm + ' reveal'}>
            <label htmlFor="email">Email:</label>
            <MaskedInput mask={emailMask} required type="text" id="email" name="email" value={email} onChange={changeEmail} />
          </ViewportBlock>
          <ViewportBlock className={styles.itemForm + ' reveal'}>
            <label htmlFor="phone">Телефон:</label>
            <MaskedInput mask={['+',/\d/,'-','(', /[1-9]/, /\d/, /\d/, ')', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]} required type="text" id="phone" name="phone" value={phone} onChange={changePhone} />
          </ViewportBlock>
          <ViewportBlock className={styles.itemForm + ' reveal'}>
            <label htmlFor="name">Сообщение:</label>
            <textarea id="subject" name="message" rows="8" spellCheck="false" value={message} onChange={changeMessage}></textarea>
          </ViewportBlock>
          <ViewportBlock className="reveal">
            <input type="submit" id="submit" value="Отправить" className={styles.btnSubmit} onClick={(e)=>handleSubmit(e)} />
          </ViewportBlock>
        </form>
      </ViewportBlock>
    )
    setLoaded(false)
  } else {
    return(
      <div className={styles.loading}>
        <ClipLoader
          loading={loading}
          color={"#00a241"}
        />
      </div>
    )
  }
}

export default ContactForm