import * as React from "react"

// styles

// markup
const IonIosTelephone = () => {
  return (
    <svg viewBox="0 0 35 35">
        <path d="M 22.600145,30.630582 C 21.128252,30.155602 20.353901,29.753292 18.437551,28.467985 14.118892,25.571392 10.1449,21.589292 7.3565289,17.364352 4.7370393,13.395307 4.0766326,11.742906 4.0766326,9.1579258 c 0,-1.470948 0.057298,-1.691214 0.6764226,-2.5991398 C 5.1987571,5.9051293 5.8908966,5.2882527 6.7823079,4.7501367 7.9693862,4.0335452 8.2572982,3.9445521 9.1319651,4.0238106 10.402575,4.1389439 11.335798,4.8673112 12.678273,6.791693 c 1.384977,1.9853083 1.808036,2.8629358 1.947432,4.039997 0.106077,0.895814 0.03214,1.166683 -0.654608,2.394537 -0.569642,1.018642 -0.739159,1.524204 -0.641507,1.913307 0.07333,0.292029 1.426168,1.822953 3.021247,3.418853 3.263873,3.265567 3.352537,3.304917 5.1046,2.265634 1.411395,-0.837203 2.616008,-0.837441 4.368946,-7.67e-4 2.983048,1.423676 5.103227,3.582859 5.098978,5.19278 -0.003,1.512492 -1.783306,3.775687 -3.626688,4.612944 -1.08779,0.494073 -3.168351,0.494816 -4.696528,0.0015 z" />
    </svg>
  )
}

export default IonIosTelephone
