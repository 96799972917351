import * as React from "react"

// styles

// markup
const BgsLogo = () => {
  const green = {
    fill: "#0f9347"
  }
  const yellow = {
    fill: "#f4eb26"
  }
  return (
    <svg viewBox="0 0 1261.1 1047.6">
      <path style={yellow} d="M 831.8,231.6 C 827.6,352 943.5,380 943.5,380 c 0,0 120.4,42.5 214.4,6.6 l -165,535.3 c 0,0 -35.9,99.3 -137.3,99.3 h -756 c 0,0 -74,8.3 -77,-81.1 l 319.9,-2.9 c 0,0 156.2,-29 201.9,-163.5 l 0.1,-0.3 c 2.4,-6.7 4.4,-13.7 6.2,-21 L 764.8,17.9 h 416.1 c 0,0 69.2,-3.6 56.5,74.6 l -290.6,5.7 c 0,-0.1 -110.7,11.2 -115,133.4 z" />
	    <path style={green} d="M 394.90039 0 C 300.10039 1.9 263.4 74.399609 257 113.59961 L 144 496.40039 L 136.59961 518.69922 L 330.90039 520.30078 C 439.00039 521.10078 526.20078 609.70078 525.30078 717.80078 C 524.90078 770.20078 504.10078 819.3 466.80078 856 C 429.90078 892.3 381.2 912.30078 329.5 912.30078 L 327.90039 912.30078 L 15.400391 909.90039 L 1.3007812 955 L 0.59960938 958.19922 C -2.1003906 981.89922 3.6003906 1001.6992 17.400391 1017.1992 C 44.200391 1047.2992 92.400391 1047.5996 94.400391 1047.5996 L 858.30078 1047.5996 C 971.30078 1047.5996 1011.3 940.59961 1013 936.09961 L 1180.8008 373.90039 L 987.30078 373.90039 C 915.60078 373.90039 857.30078 315.6 857.30078 244 C 857.30078 172.2 915.60078 113.90039 987.30078 113.90039 L 1261.0996 113.90039 L 1260.9004 96.599609 C 1260.1004 37.599609 1215.4004 8.1007813 1191.9004 0.80078125 L 1189.4004 0 L 394.90039 0 z M 774.23047 34.099609 L 1183.9004 34.099609 C 1191.5004 37.199609 1216.8996 49.499219 1224.5996 79.699219 L 987.30078 79.699219 C 896.80078 79.699219 823.19922 153.40039 823.19922 243.90039 C 823.19922 334.30039 896.80078 407.90039 987.30078 407.90039 L 1135 407.90039 L 980.69922 924.80078 C 978.29922 931.00078 944.69922 1013.5 858.19922 1013.5 L 94.400391 1013.5 C 94.100391 1013.5 59.400391 1013 42.900391 994.5 C 36.000391 986.7 33.200391 976.59961 34.400391 963.59961 L 40.300781 944.19922 L 327.59961 946.40039 L 329.5 946.40039 C 390.2 946.40039 447.50078 923.00078 490.80078 880.30078 C 516.59593 854.91788 535.45053 824.42447 546.86914 791.14844 C 546.7498 791.73565 546.61564 792.82315 546.5 793.30078 L 548.39258 786.99023 C 548.92194 785.34376 549.39067 783.68109 549.88281 782.02148 L 774.23047 34.099609 z " />
      <ellipse style={yellow} cx="285.3" cy="721" rx="179" ry="145.4" />
      <path style={green} d="M283.9,883.9c-110,0-199.4-73.5-199.4-163.9s89.5-163.9,199.4-163.9S483.3,629.7,483.3,720    S393.8,883.9,283.9,883.9z M283.9,581.8C188,581.8,110,643.8,110,720s78,138.3,173.8,138.3s173.8-62,173.8-138.3    S379.7,581.8,283.9,581.8z" />
      <path style={green} d="M143.3,765.8v-98.6h44.3c11.4,0,20,2.9,25.8,8.7c4.4,4.4,6.6,9.9,6.6,16.6c0,9.8-4.7,17.2-14.1,22    c6.3,2.1,11.1,5.1,14.5,8.9s5.1,8.9,5.1,15.4c0,8.7-3.3,15.3-9.8,20c-6.6,4.7-15.4,7-26.6,7H143.3z M160.4,708.7h23.7    c5.8,0,10.3-1.2,13.6-3.5s5-5.7,5-10.2c0-3.9-1.5-6.9-4.5-9.2c-3-2.2-7.3-3.3-12.8-3.3h-25V708.7z M160.4,750.5h28.7    c6,0,10.7-1.2,14-3.5c3.3-2.4,5-5.8,5-10.3c0-4.2-1.7-7.5-5.1-9.8c-3.4-2.3-8.5-3.4-15.2-3.4h-27.4V750.5z" />
      <path style={green} d="M240.7,716.5c0-6.8,1.3-13.4,3.8-19.6c2.5-6.2,6-11.6,10.4-16.3c4.4-4.6,9.8-8.3,16.2-11    c6.4-2.7,13.2-4.1,20.4-4.1c8.3,0,15.3,1.1,21.1,3.3s11.4,5.5,16.7,10l-11,13.1c-4.2-3.6-8.4-6.2-12.6-8c-4.1-1.7-9.2-2.6-15-2.6    c-5.9,0-11.3,1.6-16.3,4.8c-4.9,3.2-8.8,7.4-11.6,12.8c-2.8,5.4-4.2,11.2-4.2,17.6c0,6.7,1.4,12.8,4.1,18.1    c2.8,5.3,6.7,9.5,11.8,12.6c5.1,3.1,11,4.6,17.6,4.6c9.3,0,17.3-2.5,23.9-7.4v-18.5h-25.2v-15.1h41.9v41.4    c-12,10.3-25.7,15.4-41.1,15.4c-10.1,0-19-2.2-26.9-6.7c-7.8-4.5-13.8-10.6-18-18.3C242.7,734.8,240.7,726.1,240.7,716.5z" />
      <path style={green} d="M348.8,751.4l10.5-12.4c4.9,4.3,9.8,7.5,14.8,9.6c5,2.1,10.4,3.2,16.3,3.2c5.4,0,9.6-1.1,12.8-3.4    c3.1-2.3,4.7-5.4,4.7-9.3c0-2.5-0.6-4.5-1.7-6.2c-1.1-1.6-3.3-3.2-6.6-4.7c-3.2-1.5-7.9-2.9-13.9-4.4c-5.7-1.3-10.4-2.7-14.1-4.2    c-3.7-1.4-7.1-3.3-10.1-5.5c-3-2.2-5.2-5-6.6-8.2c-1.4-3.2-2.1-7.1-2.1-11.7c0-8.4,3.2-15.3,9.5-20.6c6.3-5.3,14.4-8,24.2-8    c13.6,0,25.5,4.1,35.8,12.4l-9.3,13.1c-9.1-6.8-18-10.2-26.7-10.2c-5,0-8.9,1.1-11.8,3.4c-2.9,2.3-4.3,5.1-4.3,8.6    c0,2.6,0.7,4.8,2,6.5c1.3,1.7,3.6,3.3,6.9,4.7c3.3,1.4,8.1,2.9,14.5,4.4c11,2.7,19,6.2,24.1,10.6c5.1,4.4,7.6,10.6,7.6,18.5    c0,9.1-3.2,16.3-9.7,21.5c-6.5,5.3-15,7.9-25.5,7.9C374.2,767.2,360.5,761.9,348.8,751.4z" />
    </svg>
  )
}

export default BgsLogo
