import * as React from "react"

// styles

// markup
const IonIosLocation = () => {
  return (
    <svg viewBox="0 0 35 35">
        <path d="M 14.970843,31.974283 C 12.192239,27.832844 8.1238393,19.942946 6.9196363,16.360477 6.1813143,14.163981 6.0101293,12.836166 6.1603163,10.470702 6.3769523,7.0585803 7.1307223,5.2830493 9.2714323,3.1423393 11.664291,0.74948128 13.65552,-1.5958184e-8 17.620039,-1.5958184e-8 c 3.015836,0 3.76566,0.161912695958184 5.51658,1.191212315958184 5.158135,3.032275 6.921721,8.316054 4.990286,14.9511277 -1.445788,4.966724 -8.92412,18.305142 -10.570707,18.854005 -0.250705,0.08357 -1.414117,-1.276357 -2.585355,-3.022062 z m 5.08229,-18.197921 c 1.523971,-1.523971 1.669608,-3.341099 0.397403,-4.9584387 -2.733152,-3.474646 -8.061388,-0.264506 -6.3519,3.8268737 1.133798,2.713567 3.855312,3.23075 5.954497,1.131565 z" />
    </svg>
  )
}

export default IonIosLocation
