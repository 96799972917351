// extracted by mini-css-extract-plugin
export var active = "menuzord-module--active--rzjuP";
export var menuzordBrand = "menuzord-module--menuzordBrand--OETpY";
export var menuzordContainer = "menuzord-module--menuzordContainer--aaD4r";
export var menuzordContainerAnimate = "menuzord-module--menuzordContainerAnimate--p8ZVf";
export var menuzordContainerFixed = "menuzord-module--menuzordContainerFixed--OPVOq";
export var menuzordContainerLight = "menuzord-module--menuzordContainerLight--ToCWS";
export var menuzordContainerTrans = "menuzord-module--menuzordContainerTrans--9pPtR";
export var menuzordMenu = "menuzord-module--menuzordMenu--xXr15";
export var menuzordMenu2 = "menuzord-module--menuzordMenu2--+1am1";
export var menuzordSvg = "menuzord-module--menuzordSvg--XzHD2";
export var showHide = "menuzord-module--showHide--ySG9u";
export var showhidemenu = "menuzord-module--showhidemenu--O9nJ8";